.errorMessage {
    background-color: #f9ebeb;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Roboto";
    color: #911d22;
    overflow: hidden;
}
.errorMessage .mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.errorMessage .container,
.errorMessage .container > .row,
.errorMessage .container > .row > div {
    height: 100%;
}

.errorMessage .countUp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.errorMessage .text {
    font-size: 2rem;
    text-align: center;
}
